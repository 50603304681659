<template>
  <v-overlay absolute :value="value" z-index="1">
    <v-alert type="info">
      <template #prepend>
        <v-progress-circular
          indeterminate
          color="primary"
          size="20"
          width="2"
        />
      </template>

      <div class="text-h5 ml-4" v-text="$t('invoice.status.signing')" />
    </v-alert>
  </v-overlay>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class InvoiceSigningMessage extends Vue {
  @Prop(Boolean) readonly value!: boolean;
}
</script>
