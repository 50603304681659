<template>
  <v-alert text type="warning" v-if="!canIssue">
    <div class="text-h5" v-text="$t('alert.ucfe.config.incomplete')" />
    <p v-text="$t('alert.ucfe.config.incomplete.description')" />
    <v-divider class="my-4 warning" style="opacity: 0.22"></v-divider>
    <v-row align="center" no-gutters>
      <v-spacer></v-spacer>
      <v-col class="shrink">
        <v-btn
          color="primary"
          :to="{ name: 'companies.update', params: { id: company.id } }"
          outlined
        >
          {{ $t("edit.company.data") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script lang="ts">
import { AppModule } from "@/store/app";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class AlertUcfeWarning extends Vue {
  get company() {
    return AppModule.company;
  }

  get firm() {
    return this.company ? this.company.firm : null;
  }

  get canIssue() {
    return (
      !!this.company &&
      !!this.firm &&
      !!this.firm.doc_id &&
      !!this.company.ucfe_code &&
      !!this.company.ucfe_password &&
      !!this.company.ucfe_terminal
    );
  }
}
</script>
